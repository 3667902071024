import $ from "jquery";

export const registerClearHandler = (dom, target, name = "") => {
  $(dom).on("click", () => {
    if (
      $(target)[0].options.length > 0 &&
      $(target)[0].options[0].value !== "" &&
      confirm(`確定要清除${name}嗎？`) === true
    ) {
      $(target)[0].options.length = 0;
      const option = document.createElement("option");
      option.text = "";
      option.value = "";
      $(target)[0].add(option);
    }
  });
};

export const registerSimpleClearHandler = (dom, target, name = "") => {
  $(dom).on("click", () => {
    if ($(target).val() !== "" && confirm(`確定要清除${name}嗎？`) === true) {
      $(target).val("");
    }
  });
};
