import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["quesitonTitle", "questionTitleInput", "questions"];

  connect() {
    // alert("Hello, Stimulus!");
  }

  open() {
    console.log("open");
    // this.textareaTarget.style.display = "block";
  }

  addQuestion() {
    console.log("addQuestion");
    // const index = this.questionTitleInputTargets.length;
    // const newQuestion = document.createElement("div");

    // console.warn(this.questionsTarget);
    // this.questionsTarget.insertAdjacentElement("beforeend", newQuestion);
  }

  removeQuestion(e) {
    e.preventDefault();
    $("<input />")
      .attr("type", "hidden")
      .attr("name", "commit")
      .attr("value", $(e.target).attr("value"))
      .appendTo(e.target.form);
    $("<input />")
      .attr("type", "hidden")
      .attr("name", "question_id")
      .attr(
        "value",
        $(e.target).parent().parent().children("div")[1].firstChild.value
      )
      .appendTo(e.target.form);
    console.log($(e.target).attr("name"));
    console.log(
      $(e.target).parent().parent().children("div")[1].firstChild.value
    );
    if (confirm("Are you sure you want to delete this question?")) {
      e.target.form.submit();
    }
  }

  updateTitle({ target }) {
    const index = this.questionTitleInputTargets.findIndex((t) => t === target);
    this.quesitonTitleTargets[index].innerText = target.value;
  }
}
