import $ from 'jquery'

$(function () {
    $("#add_vendor").on('click', function (e) {
        e.stopImmediatePropagation()
        e.preventDefault();
        var lastField = $("#new-vendor-filed div:last");
        var intId = (lastField && lastField.length && lastField.data("idx") + 1) || 1;
        var fieldWrapper = $("<div class=\"fieldwrapper\" id=\"field" + intId + "\"/>");
        fieldWrapper.data("idx", intId);
        var fName = $(`<input type=\"text\" class=\"form-control string optional\" name=\"sdk_application[vendor_id][${intId}]\"/>`);
        var removeButton = $(`<input type=\"button\" class=\"btn btn-danger sdk-application-delete-button[${intId}]\" value=\"Delete\" />`);

        removeButton.on('click', function () {
            $(this).parent().remove();
        });
        fieldWrapper.append(fName);
        fieldWrapper.append(removeButton);
        $("#new-vendor-filed").append(fieldWrapper);
    })

    if ($('textarea[id="edit_sdk_application_vendor_id_values"]').length === 1) {
        var venderIdValues = JSON.parse($('textarea[id="edit_sdk_application_vendor_id_values"]').val())
    }

    $("#edit_vendor").on('click', function (e) {
        e.stopImmediatePropagation()
        e.preventDefault();
        var lastField = $("#edit-vendor-filed div");
        var intId = (lastField && lastField.length) ? (Number($("#edit-vendor-filed div").get(-1).id.split(/(\d+)/)[1]) + 1) : 1;
        var fieldWrapper = $("<div class=\"fieldwrapper\" id=\"field" + intId + "\"/>");
        fieldWrapper.data("idx", intId);
        var fName = $(`<input type=\"text\" class=\"form-control string optional\" name=\"sdk_application[vendor_arr][${intId}]\"/>`);
        var removeButton = $(`<input type=\"button\" class=\"btn btn-danger sdk-application-delete-button[${intId}]\" value=\"Delete\" />`);

        removeButton.on('click', function () {
            $(this).parent().remove();
        });
        fieldWrapper.append(fName);
        fieldWrapper.append(removeButton);
        $("#edit-vendor-filed").append(fieldWrapper);
    })

    if (venderIdValues) {

        venderIdValues.forEach(function (item, i) {
            var fieldWrapper = $("<div class=\"fieldwrapper\" id=\"field" + i + "\"/>");
            var fName = $(`<input type=\"text\" class=\"form-control string optional\" name=\"sdk_application[vendor_arr][${i}]\" value=\"${item}\" />`);
            var removeButton = $(`<input type=\"button\" class=\"btn btn-danger sdk-application-delete-button[${i}]\" value=\"Delete\" />`);
            fieldWrapper.append(fName);
            fieldWrapper.append(removeButton);
            $("#edit-vendor-filed").append(fieldWrapper);

            removeButton.on('click', function () {
                let index = Number($(this).attr('class').split(/(\d+)/)[1])
                if (index == 0) {
                    alert("must be set one team_id/apk_signature_sha1")
                } else {
                    $(this).parent().remove();
                }
            });
        });
    }
})