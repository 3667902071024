import $ from 'jquery'

const ADD_SELECTOR = '.add-more-action'
const GROUP_SELECTOR = '.js-action_group'
const HIDE_SELECTOR = '.action_values'
const REMOVE_BTN_SELECTOR = '.remove-action'

const ACTIONS_ACTION_PRE = 'actions'
const ACTION_FIELDS_PRE = 'action_fields'

const TPL_SELECTOR = '#js-tpl-action_form'
const AJAX_OBJ_ID = ".js_action_obj_id"

$(function () {
  $(document).on('click', ADD_SELECTOR, function (e) {
    const $addBtn = $(e.currentTarget)
    const $group = $addBtn.parent().siblings(TPL_SELECTOR).contents(GROUP_SELECTOR + ':eq(0)')
    const $cloneGroup = $group.clone()
    $cloneGroup.find(HIDE_SELECTOR).css('display', 'none')
    $cloneGroup.find(HIDE_SELECTOR).each(function () {
      const $child = $(this).find('input, select, textarea')
      const defaultValue = $child.attr('data-default')
      $child.val(defaultValue ? defaultValue : '')
    })
    $cloneGroup.find(REMOVE_BTN_SELECTOR).parent().css('display', 'block')
    $addBtn.before($cloneGroup)
    reorder($(GROUP_SELECTOR))
  })
})

export function reorder($cloneGroup) {
  let idx = 0
  $cloneGroup.each(function () {
    const $targetGroup = $(this)
    $targetGroup.find('[name^="' + ACTIONS_ACTION_PRE + '"]').each(function () {
      this.name = this.name.replace(/\d+/, idx)
    })
    $targetGroup.find('[name^="' + ACTION_FIELDS_PRE + '"]').each(function () {
      this.name = this.name.replace(/\d+/, idx)
    })
    idx++
  })
}
