import $ from 'jquery'

const CHECK_LIGHT_ID_BUTTON = '#check_light_id_is_used'

$(function () {

    $(CHECK_LIGHT_ID_BUTTON).on("click", function () {
        let value = $("#light_id").val()
        if (Number(value) > 0) {
            console.log("是真的")
            $.ajax({
                url: `/lights/${value}`,
                method: "GET",
                dataType: "json",
                success: function (response) {
                    alert("此ID已使用")
                },
                error: function (thrownError) {
                    alert(`可以使用ID:${value}`)
                }

            })
        } else {
            alert("請輸入正確數值")
        }
    })
})