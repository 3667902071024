import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";

export default class extends Controller {
  static targets = ["textarea"];

  connect() {
    this.messageId = new URL(window.location.href).pathname
      .toString()
      .match(/\d+/);
    if (!this.messageId) {
      this.textareaTarget.remove();
      return;
    }

    const TEMPLATES = [];
    Rails.ajax({
      url: "/messages/templates",
      type: "get",
      success: ({ templates }) => {
        templates.forEach(({ id, title, contents }) => {
          if (contents) {
            TEMPLATES.push({
              name: `${id}.${title}`,
              html: contents,
            });
          }
        });
      },
      error: (response) => {
        console.error(response);
      },
    });

    const initEditor = suneditor.init({
      plugins: plugins,
      templates: TEMPLATES,
      buttonList: [
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock"],
        ["paragraphStyle", "blockquote"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["fontColor", "hiliteColor", "textStyle"],
        ["removeFormat"],
        "/", // Line break
        ["outdent", "indent"],
        ["align", "horizontalRule", "list", "lineHeight"],
        ["table", "link", "image", "video", "audio" /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
        ["fullScreen", "showBlocks", "codeView"],
        ["preview", "print"],
        ["save", "template"],
        /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
      ],
      height: 300,
      width: "100%",
      callBackSave: this.callBackFunction,
      imageUploadUrl: new URL(window.location.href).pathname
        .toString()
        .replace("/edit", "/attach_image"),
    });
    this.editor = initEditor.create("sample");

    this.editor.onImageUpload = this.onImageUpload;

    if (this.element.dataset.contents === "true") {
      Rails.ajax({
        url: `/messages/${this.messageId}.json`,
        type: "get",
        success: ({ contents }) => {
          this.setContents(contents);
        },
        error: (response) => {
          console.error(response);
        },
      });
    }

    // fix fixed textarea, style is in messages.scss
    document.querySelector("textarea.se-wrapper-code").id = "codeView";
  }

  onImageUpload(targetElement, index, state, info, remainingFilesCount, core) {
    if (state === "delete") {
      // TODO handle delete image
      return;
    } else {
      setTimeout(() => {
        document.querySelector("[data-command=save]").click();
      }, 500);
    }
  }

  callBackFunction(contents, isChanged) {
    if (!isChanged) return;

    const url = new URL(window.location.href).pathname
      .toString()
      .replace("/edit", ".json");
    const data = {
      contents,
    };
    Rails.ajax({
      url,
      type: "patch",
      data: new URLSearchParams(data).toString(),
      success: (response) => {
        console.log(response);
      },
      error: (response) => {
        console.error(response);
      },
    });
  }

  setContents(data) {
    this.editor.setContents(data);
  }

  beforeSubmit(e) {
    e.preventDefault();

    const saveBtn = document.querySelector("[data-command=save]");
    if (saveBtn) {
      saveBtn.click();
      setTimeout(() => {
        e.target.parentElement.submit();
      }, 0);
    } else {
      e.target.parentElement.requestSubmit();
    }
  }
}
