import $ from 'jquery'
import { ajaxReturnIdAndName } from 'lib/select_ajax'


const PROJECT_ID = "#js_project_search"

$(function () {
    if (PROJECT_ID) {
        ajaxReturnIdAndName(PROJECT_ID, "專案", "/projects", "projects")
    }
})