import $ from "jquery";
import {
  ajaxReturnIdAndName,
  AjaxReturnIdFilenameUrl,
  ajaxReturnIdAndFilename,
} from "lib/select_ajax";
import {
  registerClearHandler,
  registerSimpleClearHandler,
} from "lib/clear_field";

$(() => {
  [0, 1, 2].forEach((i) => {
    registerClearHandler(
      "#clear_border_image_" + i,
      "#js_border_asset_selector_" + i,
      "邊框圖片"
    );
    // for query asset type is border
    const ajaxBorderAssetSearch = "#js_border_asset_selector_" + i;
    if (ajaxBorderAssetSearch) {
      ajaxReturnIdAndFilename(
        ajaxBorderAssetSearch,
        "檔名",
        "/upload_files",
        "5",
        "files"
      );
    }
  });
  registerClearHandler(
    "#clear_preview_image",
    "#js_preview_asset_selector",
    "預覽圖片"
  );
  registerSimpleClearHandler(
    "#clear_thumbnail",
    "#ar_object_thumbnail",
    "AR物件縮圖"
  );
});

// TODO: refactor
const MODELS_TYPE_SELECTOR = ".js-ar_object_models_type";
const GROUP_SELECTOR = ".js-model_group";
const hideSelector = ".model_values";
const HEIGHT = "#ar_object_model_height";
const WIDTH = "#ar_object_model_width";
const ASSETS = "#ar_object_model_files_list";
const VIDEO = "#ar_object_model_video_list";
const DEFAULT_FIELDS = ".default_fields";
const COMMON_FIELDS = [".common_model_fields"];
const changeConditions = {
  1: [HEIGHT, WIDTH, ASSETS].join(", "),
  2: ["#ar_object_model_radius", ASSETS, DEFAULT_FIELDS].join(", "),
  5: [
    "#ar_object_model_is_size_scale_lock",
    "#ar_object_model_bloom_intensity",
    "#ar_object_model_bloom_radius",
    HEIGHT,
    WIDTH,
    ASSETS,
    DEFAULT_FIELDS,
  ].join(", "),
  8: [".type_8", HEIGHT, WIDTH, DEFAULT_FIELDS].join(", "),
  9: [".type_9", DEFAULT_FIELDS, VIDEO].join(", "),
  11: ".type_11",
  12: ".type_11",
  13: ".type_13",
  14: ["#ar_object_model_preview_url", ".type_9", VIDEO].join(", "),
  15: ASSETS,
  16: [".type_16", DEFAULT_FIELDS, ASSETS].join(", "),
  17: ".type_17",
  18: ".type_18",
  19: ".type_13",
  20: [".type_20", HEIGHT, WIDTH, "#ar_object_model_preview_url"].join(", "),
  21: [
    ".type_21",
    HEIGHT,
    WIDTH,
    "#ar_object_model_preview_url",
    "#ar_object_model_border_url",
  ].join(", "),
  22: [DEFAULT_FIELDS, ASSETS].join(", "),
  23: ["#ar_object_model_radius", "#ar_object_model_is_hidden", VIDEO].join(
    ", "
  ),
  24: [".type_24", DEFAULT_FIELDS].join(", "),
};

$(function () {
  $(document).on("change", MODELS_TYPE_SELECTOR, function (e) {
    const $modelsType = $(e.currentTarget);
    const modelType = $modelsType.val();
    const parentNode = $modelsType.parents(GROUP_SELECTOR + ":eq(0)").get(0);
    const asset = ["5", "9"];
    const Model = ["8"];

    const search = function (selector) {
      return $(selector, parentNode);
    };

    const modelsType = $modelsType.val();
    const targetChangeType = [
      changeConditions[modelsType].split(", ").concat(COMMON_FIELDS),
    ].join(", ");
    if (!targetChangeType) {
      search(hideSelector).css("display", "none");
    }
    search(hideSelector).css("display", "none");
    search(targetChangeType).css("display", "block");
  });
  $(GROUP_SELECTOR).each(function () {
    const $group = $(this);
    const $modelsType = $group.find(MODELS_TYPE_SELECTOR + ":eq(0)");
    const modelsType = $modelsType.val();

    const search = function (selector) {
      return $(selector, $group.get(0));
    };

    if (changeConditions[modelsType]) {
      const targetChangeType = [
        changeConditions[modelsType].split(", ").concat(COMMON_FIELDS),
      ].join(", ");

      if (targetChangeType) {
        search(hideSelector).css("display", "none");
        search(targetChangeType).css("display", "block");
      }
    }
  });

  var ajaxAssetSearch = $("#js_asset_selector");
  var ajaxIosTypeSearch = $("#ios_asset_selector");
  var ajaxAndroidTypeSearch = $("#android_asset_selector");
  var ajaxVideoTypeSearch = $("#js_video_asset_selector");
  var ajaxPreviewAssetSearch = $("#js_preview_asset_selector");
  var ajaxSceneSearch = $("#js_scene_search");
  var js_new_poly_asset_selector = $(".new_poly_photos_selector");
  const ajaxImageSequenceTypeSearch = $("#js_image_sequence_asset_selector");

  //for query asset type is image
  if (ajaxAssetSearch) {
    AjaxReturnIdFilenameUrl(
      ajaxAssetSearch,
      "檔名",
      "/upload_files",
      "5",
      "files"
    );
  }

  //for query asset type is video
  if (ajaxVideoTypeSearch) {
    AjaxReturnIdFilenameUrl(
      ajaxVideoTypeSearch,
      "檔名",
      "/upload_files",
      "9",
      "files"
    );
  }

  //for query asset for preview asset
  if (ajaxPreviewAssetSearch) {
    ajaxReturnIdAndFilename(
      ajaxPreviewAssetSearch,
      "檔名",
      "/upload_files",
      "5",
      "files"
    );
  }

  //for query asset type is ios model
  if (ajaxIosTypeSearch) {
    AjaxReturnIdFilenameUrl(
      ajaxIosTypeSearch,
      "檔名",
      "/upload_files",
      "8",
      "files"
    );
  }

  //for query asset type is android model
  if (ajaxAndroidTypeSearch) {
    AjaxReturnIdFilenameUrl(
      ajaxAndroidTypeSearch,
      "檔名",
      "/upload_files",
      "8",
      "files"
    );
  }

  if (ajaxImageSequenceTypeSearch) {
    AjaxReturnIdFilenameUrl(
      ajaxImageSequenceTypeSearch,
      "檔名",
      "/upload_files",
      "8",
      "files"
    );
  }

  //for query scene
  if (ajaxSceneSearch) {
    ajaxReturnIdAndName(ajaxSceneSearch, "場景", "/scenes", "scenes");
  }

  //disable event/action if skipModelTypeIdsArr include value
  var skipModelTypeIdsArr = ["10", "11", "12", "13", "16"];

  if (skipModelTypeIdsArr.includes($("#ar_object_model_type").val())) {
    var val = $("#ar_object_model_type").val();
    if (val == "13") {
      $("#subevent-tab").attr("style", "display:block");
      $("#contact-tab").attr("style", "display:none");
    } else {
      $("#subevent-tab").attr("style", "display:none");
      $("#contact-tab").attr("style", "display:none");
    }
  }

  $("#ar_object_model_type").on("change", function () {
    if (skipModelTypeIdsArr.includes(this.value)) {
      if (this.value == "13") {
        $("#subevent-tab").attr("style", "display:block");
        $("#contact-tab").attr("style", "display:none");
      } else {
        $("#contact-tab").attr("style", "display:none");
        $("#subevent-tab").attr("style", "display:none");
      }
    } else {
      $("#contact-tab").attr("style", "display:block");
      $("#subevent-tab").attr("style", "display:none");
    }
  });

  var floorCountField = $("input[name='fields[floor_count]']");

  //set ar_object model type 13 floor_angle and face_gap_list fields
  $("input[name='fields[floor_count]']").on("change", floorCount);

  function floorCount() {
    $(".ar_object_model_floor_angles").remove();
    $(".ar_object_model_face_gap_list").remove();

    for (var step = 1; step <= this.value; step++) {
      var floor_angles_fieldWrapper = $(
        '<div class="form-group integer optional ar_object_model_floor_angles">'
      );
      var floor_angles_label = $(
        `<label class=\"float optional\" for=\"ar_object_model\">第${step}層角度</label>`
      );
      var floor_angles_input = $(
        `<input class=\"form-control numeric integer optinal\" name=\"fields[floor_angles][${step}]\" type=\"number\">`
      );
      floor_angles_fieldWrapper.append(floor_angles_label);
      floor_angles_fieldWrapper.append(floor_angles_input);

      var face_gap_list_fieldWrapper = $(
        '<div class="form-group integer optional ar_object_model_face_gap_list">'
      );
      var face_gap_list_label = $(
        `<label class=\"float optional\" for=\"ar_object_model\">第${step}層間距</label>`
      );
      var face_gap_list_input = $(
        `<input class=\"form-control numeric integer optinal\" name=\"fields[face_gap_list][${step}]\" type=\"number\">`
      );
      face_gap_list_fieldWrapper.append(face_gap_list_label);
      face_gap_list_fieldWrapper.append(face_gap_list_input);

      $("#ar_object_model_floor_count").append(floor_angles_fieldWrapper);
      $("#ar_object_model_floor_count").append(face_gap_list_fieldWrapper);
    }
  }

  //set ar_object model type 13 photos fields
  $("input[name='fields[face_count]']").on("change", renderPhotos);

  function renderPhotos() {
    $(".ar_object_model_photos").remove();
    var floor_count = parseInt($("input[name='fields[floor_count]']").val());

    for (var step = 1; step <= floor_count; step++) {
      for (var count = 1; count <= this.value; count++) {
        var domId = `js_new_poly_asset_selector_${step}_${count}`;

        var fieldWrapper = $(
          '<div class="form-group select optional ar_object_model_photos">'
        );
        var label = $(
          `<label class=\"select optional\" for=\"ar_object_model\">第${step}層-第${count}個位置</label>`
        );
        var select = $(
          `<select class=\"js_new_poly_asset_selector form-control select optional\" name=\"fields[photos][${step}][${count}]\" id=\"${domId}\"></select>`
        );

        fieldWrapper.append(label);
        fieldWrapper.append(select);
        $("#ar_object_model_face_count").append(fieldWrapper);

        //add select2 ajax to DOM
        ajaxReturnIdAndFilename(
          `#${domId}`,
          "檔名",
          "/upload_files",
          "5",
          "files"
        );
      }
    }
  }

  if (js_new_poly_asset_selector.length != 0) {
    js_new_poly_asset_selector.each(function () {
      ajaxReturnIdAndFilename(
        `#${this.id}`,
        "檔名",
        "/upload_files",
        "5",
        "files"
      );
    });
  }
});
