import $ from 'jquery'

const EVENTS_TYPE_SELECTOR = '.js-ar_object_sub_events_type'
const GROUP_SELECTOR = '.js-sub_events_group'
const hideSelector = '.event_values-'
const changeConditions = {
    '5': "#ar_object_sub_events_activate_distance",
    '6': "#ar_object_sub_events_look_at_time"
}

$(function () {
    $(document).on('change', EVENTS_TYPE_SELECTOR, function (e) {
        const $eventsType = $(e.currentTarget)
        var index = $eventsType.attr('id').split('ar_object_events_type').at(-1).replace(/\[|]/g, '')

        const eventType = $eventsType.val()
        const parentNode = $eventsType.parents(GROUP_SELECTOR + ':eq(0)').get(0)

        const search = function (selector) {
            return $(selector, parentNode)
        }

        const targetChangeType = changeConditions[eventType]

        if (!targetChangeType) {
            var class_name = `${hideSelector}${index}`
            $(class_name).css("display", "none");
        } else {
            var class_name = `${hideSelector}${index}`
        }

        var id_name = `${targetChangeType}-${index}`
        $(class_name).css('display', 'none')
        $(id_name).css('display', 'block')
    })

    $(GROUP_SELECTOR).each(function () {
        const $group = $(this)
        const $eventsType = $group.find(EVENTS_TYPE_SELECTOR)
        var ek = $($eventsType).map((_, el) => el.value).get()

        ek.forEach(function (value, i) {
            let num = i + 1
            let target = changeConditions[`${value}`]

            if (target) {
                $(`${target}-${num}`).css('display', 'block')
            }
        });


        const search = function (selector) {
            return $(selector, $group.get(0))
        }

    })

})
