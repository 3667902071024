import $ from "jquery";
import "select2/dist/css/select2.css";

const ajaxReturnIdAndName = (dom, name, path, key) => {
  $(dom).select2({
    width: "100%",
    language: "zh-TW",
    containerCssClass: "form-control select optional",
    minimumInputLength: 1,
    placeholder: `搜尋${name}名稱或是${name}id...`,
    ajax: {
      delay: 300,
      url: path,
      dataType: "json",
      type: "GET",
      data: function (params) {
        return {
          search: params.term,
        };
      },
      processResults: function (data) {
        return {
          results: $.map(data[key], function (obj) {
            return {
              id: obj.id,
              text: `${obj.name} (id: ${obj.id})`,
            };
          }),
        };
      },
    },
  });
};

const AjaxReturnIdFilenameUrl = (dom, name, path, type, key) => {
  $(dom).select2({
    width: "100%",
    language: "zh-TW",
    containerCssClass: "form-control select optional",
    minimumInputLength: 1,
    placeholder: `輸入${name}或是id...`,
    ajax: {
      delay: 300,
      url: path,
      dataType: "json",
      type: "GET",
      data: function (params) {
        return {
          search: params.term,
          asset_type: type,
        };
      },
      processResults: function (data) {
        return {
          results: $.map(data[key], function (obj) {
            return {
              id: obj.id,
              text: obj.filename,
              url: obj.url,
            };
          }),
        };
      },
    },
  });
};

function ajaxReturnIdAndFilename(dom, name, path, type, key) {
  $(dom).select2({
    width: "100%",
    language: "zh-TW",
    containerCssClass: "form-control select optional",
    minimumInputLength: 1,
    placeholder: `輸入${name}或是id...`,
    ajax: {
      delay: 300,
      url: path,
      dataType: "json",
      type: "GET",
      data: function (params) {
        return {
          search: params.term,
          asset_type: type,
        };
      },
      processResults: function (data) {
        return {
          results: $.map(data[key], function (obj) {
            return {
              id: obj.url,
              text: obj.filename,
            };
          }),
        };
      },
    },
  });
}

export {
  ajaxReturnIdAndName,
  AjaxReturnIdFilenameUrl,
  ajaxReturnIdAndFilename,
};
