import $ from 'jquery'
import { reorder } from './add_action'

const REMOVE_BTN_SELECTOR = '.remove-action'

const GROUP_SELECTOR = '.js-action_group'

$(function() {
  $(document).on('click', REMOVE_BTN_SELECTOR, function(e) {
    const $removeBtn = $(e.currentTarget)
    const $group = $removeBtn.parents(GROUP_SELECTOR + ':eq(0)')

    $group.remove()
    reorder($(GROUP_SELECTOR))
  })
})