import $ from "jquery";

const ACTIONS_TYPE_SELECTOR = ".js-ar_object_actions_type";
const GROUP_SELECTOR = ".js-action_group";

const hideSelector = ".action_values";
const DEFAULT_ACTIONS = ".default_action_values";
const DIRECTION = [
  "#ar_object_actions_direction_x",
  "#ar_object_actions_direction_y",
  "#ar_object_actions_direction_z",
].join(", ");
const DEFAULT_TIME = "#ar_object_actions_time";
const TRANSPARENCY = "#ar_object_actions_transparency";
const changeConditions = {
  1: DEFAULT_ACTIONS,
  2: [
    "#ar_object_actions_img_url",
    "#ar_object_actions_urls",
    "#ar_object_actions_content",
    "#ar_object_actions_scroll",
    DEFAULT_ACTIONS,
  ].join(", "),
  3: [
    "#ar_object_actions_translatin",
    DEFAULT_TIME,
    DIRECTION,
    DEFAULT_ACTIONS,
  ].join(", "),
  5: ["#ar_object_actions_video_url", DEFAULT_ACTIONS].join(", "),
  6: DEFAULT_ACTIONS,
  7: DEFAULT_ACTIONS,
  8: [
    "#ar_object_actions_url",
    "#ar_object_actions_url_with_auth",
    DEFAULT_ACTIONS,
  ].join(", "),
  9: DEFAULT_ACTIONS,
  10: ["#ar_object_actions_animation_speed", DEFAULT_ACTIONS].join(", "),
  11: [
    "#ar_object_actions_start_frame",
    "#ar_object_actions_end_frame",
    "#ar_object_actions_fps",
    DEFAULT_ACTIONS,
  ].join(", "),
  12: ["#ar_object_actions_repeat_count", DEFAULT_ACTIONS].join(", "),
  13: [DEFAULT_TIME, DIRECTION, DEFAULT_ACTIONS].join(", "),
  14: DEFAULT_ACTIONS,
  15: ["#ar_object_actions_scale", DEFAULT_TIME, DEFAULT_ACTIONS].join(", "),
  16: [DEFAULT_TIME, DEFAULT_ACTIONS].join(", "),
  17: [DEFAULT_TIME, DEFAULT_ACTIONS].join(", "),
  18: [DEFAULT_TIME, TRANSPARENCY, DEFAULT_ACTIONS].join(", "),
  19: [DEFAULT_TIME, TRANSPARENCY, DEFAULT_ACTIONS].join(", "),
  20: [
    "#ar_object_actions_width",
    "#ar_object_actions_height",
    "#ar_object_actions_move_action_time",
    "#ar_object_actions_rotate_action_time",
    "#ar_object_actions_clockwise",
    DEFAULT_ACTIONS,
  ].join(", "),
  21: [
    "#ar_object_actions_time_5",
    "#ar_object_actions_start_angle",
    "#ar_object_actions_end_angle",
    "#ar_object_actions_radius",
    "#ar_object_actions_curve_scale",
    "#ar_object_actions_clockwise",
    DEFAULT_TIME,
    DEFAULT_ACTIONS,
  ].join(", "),
  22: [
    "#ar_object_actions_face_translating",
    "#ar_object_actions_face_is_return",
    "#ar_object_actions_face_wait_time",
    "#ar_object_actions_face_rotate_to_me",
    "#ar_object_actions_trans_rot_sync",
    DEFAULT_TIME,
    DEFAULT_ACTIONS,
  ].join(", "),
  23: ["#ar_object_actions_url", DEFAULT_ACTIONS].join(", "),
  24: ["#ar_object_actions_impact_feedback_style", DEFAULT_ACTIONS].join(", "),
  25: [
    "#ar_object_actions_scene_id",
    "#ar_object_actions_reposition_to_camera",
    DEFAULT_ACTIONS,
  ].join(", "),
  26: ["#ar_object_actions_name", DEFAULT_ACTIONS].join(", "),
  27: DEFAULT_ACTIONS,
  34: DEFAULT_ACTIONS,
  35: DEFAULT_ACTIONS,
  36: DEFAULT_ACTIONS,
  37: [
    "#ar_object_actions_info_url",
    "#ar_object_actions_panorama_url",
    DEFAULT_ACTIONS,
  ].join(", "),
  38: DEFAULT_ACTIONS,
  39: [
    "#ar_object_actions_prompt_id",
    "#ar_object_actions_recommendation_obj_id",
    DEFAULT_ACTIONS,
  ].join(", "),
  40: DEFAULT_ACTIONS,
  41: ["#ar_object_actions_url", DEFAULT_ACTIONS].join(", "),
  44: [
    "#ar_object_actions_api_url",
    "#ar_object_actions_api_method",
    "#ar_object_actions_api_payload",
    DEFAULT_ACTIONS,
  ].join(", "),
  104: "#ar_object_actions_voucher_type",
  105: "#ar_object_actions_coin_type",
  106: ["#ar_object_actions_game_type", DEFAULT_ACTIONS].join(", "),
  108: [
    "#ar_object_actions_info_url",
    "#ar_object_actions_panorama_url",
    DEFAULT_ACTIONS,
  ].join(", "),
  109: [
    "#ar_object_actions_voucher_type",
    "#ar_object_actions_frame_url",
    "#ar_object_actions_frame_icon_url",
    DEFAULT_ACTIONS,
  ].join(", "),
};

$(function () {
  $(document).on("change", ACTIONS_TYPE_SELECTOR, function (e) {
    const $actionsType = $(e.currentTarget);
    const actionType = $actionsType.val();
    const parentNode = $actionsType.parents(GROUP_SELECTOR + ":eq(0)").get(0);

    const search = function (selector) {
      return $(selector, parentNode);
    };

    const targetChangeType = changeConditions[actionType];
    if (!targetChangeType) {
      return;
    }
    search(hideSelector).css("display", "none");
    search(targetChangeType).css("display", "block");
  });
  $(GROUP_SELECTOR).each(function () {
    const $group = $(this);
    const $actionType = $group.find(ACTIONS_TYPE_SELECTOR + ":eq(0)");
    const actionType = $actionType.val();
    const REMOVE_BTN_SELECTOR = ".remove-action";

    const search = function (selector) {
      return $(selector, $group.get(0));
    };

    const targetChangeType = changeConditions[actionType];
    if (targetChangeType) {
      search(hideSelector).css("display", "none");
      search(targetChangeType).css("display", "block");
      search(REMOVE_BTN_SELECTOR).parent().css("display", "block");
    }
  });
});
